import * as React from 'react'
import styled from 'styled-components'
import { titleCase } from 'title-case'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { P } from '../shared/typography'

import ProviderSummary from '../provider/provider-summary'
import ProviderServices from '../provider/provider-services'

const getMiles = (i) => {
    return i * 0.000621371192
}

const SearchHit = ({
    distance,
    userLocation,
    title,
    categoryIcon,
    category,
    path,
    services,
    serviceAreas,
}) => {
    const hasDistance = typeof distance !== 'undefined' && distance !== null

    return (
        <article>
            <ProviderSummary
                title={title}
                icon={categoryIcon}
                category={category}
                path={path}
            >
                {hasDistance && (
                    <Distance size="subtitle">
                        {hasDistance && distance > 0 && (
                            <span>
                                <DistanceIcon
                                    icon={['far', 'bus-simple']}
                                    size="md"
                                />
                                {getMiles(distance).toFixed(1)} miles from your
                                location.
                            </span>
                        )}
                        {!(distance > 0) && (
                            <span>
                                <DistanceIcon
                                    icon={['far', 'circle-check']}
                                    size="md"
                                />
                                <span>Provider services </span>
                                {serviceAreas &&
                                    serviceAreas.map((area, index) => (
                                        <span>
                                            {index === serviceAreas.length - 1 && serviceAreas.length > 1
                                                ? 'and '
                                                : ''}
                                            {area.title}
                                            {index < serviceAreas.length - 2
                                                ? ', '
                                                : ' '}
                                        </span>
                                    ))}
                            </span>
                        )}
                    </Distance>
                )}
            </ProviderSummary>
            {services && <ProviderServices />}
        </article>
    )
}

const Distance = styled(P)`
    margin: 0 0 var(--space-xs) 0;
    padding: 0;
`

const DistanceIcon = styled(FontAwesomeIcon)`
    display: inline-block;
    padding-right: var(--space-3xs);
`

export default SearchHit
