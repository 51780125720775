import * as React from 'react'
import styled from 'styled-components'

import { H2, H3, P, UL } from '../shared/typography'
import VisuallyHidden from '../content/visually-hidden'

const ProviderServices = () => {
    return (
        <ServicesContainer>
            <VisuallyHidden as='h2'>Prodiver Services</VisuallyHidden>
            <ServicesList>
                <li>
                    <H3 size='h6'>Food Shelf</H3>
                    <P>
                        A limited number of shelf-stable food items and hygiene
                        items are available for individuals in need of
                        assistance.
                    </P>
                </li>
            </ServicesList>
        </ServicesContainer>
    )
}

const ServicesContainer = styled.section`
    padding: 0 var(--space-s) 0 var(--space-s);
`

const ServicesList = styled(UL)`
    margin: 0;
    padding: 0;
    list-style: none;
`

export default ProviderServices
