import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import find from 'lodash/find'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Theme from '../shared/colors'

import { H2, P, Span, UL } from '../shared/typography'

const Categories = ({
    items,
    currentRefinement,
    refine,
    isFromSearch,
    searchForItems,
    createURL,
}) => {
    const data = useStaticQuery(graphql`
        {
            allContentfulCategory {
                edges {
                    node {
                        id
                        icon {
                            name
                            iconName
                            style
                        }
                        title
                    }
                }
            }
        }
    `)

    const categories = data.allContentfulCategory.edges
    
    return (
        <CategoryContainer>
            {items && (
                <CategoryList>
                    {items.map((item) => (
                        <Category key={`category-${item.value}`}>
                            <CategoryWrapper
                                isRefined={item.isRefined}
                                onClick={(event) => {
                                    event.preventDefault()
                                    refine(item.value)
                                }}
                            >
                                <MappedIcon
                                    label={item.label}
                                    categories={categories}
                                />
                                <CategoriyTitle>
                                    {item.label}{' '}
                                    <CategoryTotal size="subtitle">
                                        ({item.count})
                                    </CategoryTotal>
                                </CategoriyTitle>
                            </CategoryWrapper>
                        </Category>
                    ))}
                </CategoryList>
            )}
        </CategoryContainer>
    )
}

const MappedIcon = ({ label, categories }) => {
    const category = find(categories, {
        node: {
            title: `${label}`,
        },
    })

    if (!category) {
        return <span></span>
    }

    return <CategoryIcon icon={['far', `${category.node.icon.iconName}`]} />
}

const CategoryContainer = styled.div`
    padding: 0 var(--space-m) var(--space-m) var(--space-m);
`

const CategoryList = styled(UL)`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 0;
    padding: 0;
    list-style: none;
`

const Category = styled.li`
    margin: 0;
    padding: var(--space-xs) var(--space-xs) 0 0;
    text-overflow: ellipsis;
    overflow: hidden;

    &:nth-child(odd) {
        padding: var(--space-2xs) var(--space-3xs) 0 0;
    }

    &:nth-child(even) {
        padding: var(--space-2xs) 0 0 var(--space-3xs);
    }
`
const CategoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: var(--space-xs);
    height: 100%;
    border-width: 1px 1px 4px 1px;
    border-style: solid;
    border-color: ${(props) =>
        props.isRefined ? Theme.text.link : Theme.ui.secondary};
    border-radius: var(--space-3xs);
    text-overflow: ellipsis;
    color: ${(props) =>
        props.isRefined ? Theme.text.link : Theme.text.primary};
    overflow: hidden;

    &:hover {
        background: ${Theme.text.link};
        color: ${Theme.text.inverse};
        cursor: pointer;
    }
`

const CategoryIcon = styled(FontAwesomeIcon)`
    display: block;
    padding: 0 0 var(--space-xs) 0;
`

const CategoriyTitle = styled(P)`
    display: block;
    margin: 0;
    padding: 0;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
`

const CategoryTotal = styled(Span)`
    display: block;
    margin: 0;
    padding: 0;
    overflow: hidden;
`

export default Categories
