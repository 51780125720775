import * as React from 'react'
import styled, { css } from 'styled-components'

import Theme from '../shared/colors'
import { P } from '../shared/typography'

const Tag = ({ primary, children }) => {
    const isPrimary = typeof primary !== 'undefined' ? true : false

    return (
        <TagContainer size="subtitle" primary={isPrimary}>
            {children}
        </TagContainer>
    )
}

const TagContainer = styled(P)`
    display: inline-block;
    margin: 0 0 var(--space-2xs) 0;
    padding: var(--space-3xs) var(--space-2xs);
    border: 1px solid ${Theme.ui.secondary};
    border-radius: 999vw;
    background: ${Theme.ui.inverse};
    color: ${Theme.text.primary};

    ${(props) =>
        props.primary &&
        css`
            border: 1px solid ${Theme.text.link};
            background: ${Theme.text.link};
            color: ${Theme.text.inverse};
        `}
`

export default Tag
