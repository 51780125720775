import * as React from 'react'
import styled from 'styled-components'

const Divider = styled.hr`
    margin: var(--space-s) 0;
    padding: 0;
    border-top: 1px solid black;
`

export default Divider
