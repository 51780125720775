import * as React from 'react'
import styled from 'styled-components'

import { Span } from '../shared/typography'

const VisuallyHidden = styled(Span)`
    &:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
`

export default VisuallyHidden
