import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { connectSearchBox } from 'react-instantsearch-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Button from '../content/button'
import { P, typestyle, weights } from '../shared/typography'

const DEBOUNCE_TIME = 400

const SearchBoxContainer = ({ onPostalCode }) => {
    const phraseElement = useRef(null)
    const postalElement = useRef(null)

    return (
        <Form noValidate action="" role="search">
            <Label>
                <StepLabel>Step 1</StepLabel>
                Search for&hellip;
            </Label>
            <Input
                type={'search'}
                placeholder="Food pantry, rent, etc."
                ref={phraseElement}
            />
            <Label>
                <StepLabel>Step 2 (Optional)</StepLabel>
                Services my Area
            </Label>
            <Input type="text" placeholder="Zip Code" ref={postalElement} />
            <HelpText size="caption">
                Provide a zip code and we'll match you with providers that
                operate in the area.
            </HelpText>
            <SubmitSearch
                onPostalCode={onPostalCode}
                targetElement={phraseElement}
                postalElement={postalElement}
            />
        </Form>
    )
}

const SubmitSearch = connectSearchBox(
    ({
        onPostalCode,
        targetElement,
        postalElement,
        searchType,
        currentRefinment,
        isSearchStalled,
        refine,
    }) => {
        const [debouncedSetQuery, setDebouncedSetQuery] = useState(null)

        const onSearchQueryChange = (updatedSearchQuery, postalCode) => {
            clearTimeout(debouncedSetQuery)

            setDebouncedSetQuery(
                setTimeout(() => {
                    onPostalCode(postalCode)
                    refine(updatedSearchQuery)
                }, DEBOUNCE_TIME)
            )
        }

        return (
            <SearchButton
                type="submit"
                primary
                onClick={(e) => {
                    e.preventDefault()
                    onSearchQueryChange(
                        targetElement.current.value,
                        postalElement.current.value
                    )
                }}
            >
                <FontAwesomeIcon icon={['far', 'magnifying-glass']} size="sm" />
                <strong>Search</strong>
            </SearchButton>
        )
    }
)

const Form = styled.form`
    margin: 0;
    padding: var(--space-m) var(--space-m) 0 var(--space-m);
`

const Label = styled.label`
    display: block;
    padding: var(--space-s) 0 var(--space-2xs) 0;
    font-weight: ${weights.bold};

    ${(props) => {
        props.size = props.size ? props.size : 'caption'
        return typestyle()
    }}
`

const HelpText = styled(P)`
    padding: var(--space-s) 0 var(--space-2xs) 0;
`

const ValidIcon = styled(FontAwesomeIcon)`
    float: right;
`

const StepLabel = styled.em`
    display: block;
    font-style: normal;
    font-weight: ${weights.light};

    ${(props) => {
        props.size = props.size ? props.size : 'subtitle'
        return typestyle()
    }}
`

const Input = styled.input`
    display: block;
    padding: var(--space-3xs) var(--space-xs);
    width: 100%;
    border: 1px solid black;
    border-radius: var(--space-3xs);

    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}
`

const SearchButton = styled(Button)`
    display: block;
    margin: var(--space-s) 0;
    border-radius: var(--space-3xs);
    width: 100%;

    ${(props) => {
        props.size = props.size ? props.size : 'body'
        return typestyle()
    }}

    strong {
        display: inline-block;
        padding: 0 var(--space-xs);
    }
`

export default SearchBoxContainer
