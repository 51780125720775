import * as React from 'react'
import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Link from '../shared/link'
import Tag from '../content/tag'
import { H1, P } from '../shared/typography'

const ProviderSummary = ({ path, title, category, icon, children }) => {
    return (
        <SummaryContainer>
            <ProviderTitle size="h5">
                <Link to={path}>{title}</Link>
            </ProviderTitle>
            <Tag tagType='primary'>
                <CategoryIcon icon={['far', `${icon}`]} size='lg' />
                {category}
            </Tag>
            {children}
        </SummaryContainer>
    )
}

const SummaryContainer = styled.div`
    padding: var(--space-s) var(--space-s) 0 var(--space-s);
`

const ProviderTitle = styled(H1)`
    margin: 0 0 var(--space-xs) 0;
`

const CategoryIcon = styled(FontAwesomeIcon)`
    display: inline-block;
    padding-right: var(--space-3xs);
`

export default ProviderSummary
