import React, { Fragment } from 'react'
import { Highlight, Snippet } from 'react-instantsearch-dom'
import { Link } from 'gatsby'
import get from 'lodash/get'

import SearchHit from './search-hit'

const hitHandlers = {
    ContentfulDepartment: (value) => <Department hit={value} postalCode={postalCode} />,
    default: (value, postalCode) => <ProviderHit hit={value} postalCode={postalCode} />,
}

export const PageHit = (clickHandler) => ({ hit }) => (
    <div>
        <h4 data-object-id={hit.objectID} data-position={hit.__hitIndex}>
            <a node={hit} onClick={clickHandler} className={styles.link}>
                <Highlight attribute="title" hit={hit} tagName="mark" />
            </a>
        </h4>
        <Snippet attribute="excerpt" hit={hit} tagName="mark" />
    </div>
)

export const HitComps = ({ hit, insights, postalCode }) => {
    const type = get(hit, 'internal.type')

    const handler = hitHandlers[type] || hitHandlers.default

    return (
        <div
            data-object-id={hit.objectID}
            data-position={hit.__position + 1}
        >
            {handler(hit, postalCode)}
        </div>
    )
}

/*miles,
userLocation,
title,
categoryIcon,
category,
services,*/

const ProviderHit = ({ hit, postalCode }) => {
    return (
        <SearchHit
            title={hit.title}
            path={hit.gatsbyPath}
            category={hit.primaryServiceGroup.category.title || hit.primaryServiceGroup.title}
            categoryIcon={hit.primaryServiceGroup.category.icon.iconName}
            distance={hit._rankingInfo.geoDistance}
            userLocation={postalCode}
            serviceAreas={hit.postalCodesServiced}
        />
    )
}

export const TestHit = ({ hit }) => {
    return <div>test</div>
}
